import { useAppSelector } from '@redux/hooks';

export const useIsNew = () => {
	const vendorIsNew = useAppSelector(
		(state) =>
			state.vendor.vendor?.isNew || state.vendor.vendorRaw?.isNew || false,
	);

	return Boolean(vendorIsNew);
};
