import UnifiedLightboxModal from '@components/unified-lightbox/Lightbox';
import { openUnifiedLightboxAtom } from '@components/unified-lightbox/atoms';
import { getFilterByMediaType } from '@components/unified-lightbox/helpers';
import { useSetAtom } from 'jotai';
import React, { type FC, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { afterNextPaint } from '../../../../../../../../helpers/after-next-paint';
import Gallery from '../../../../Gallery/Gallery';

interface LandscapeGalleryViewProps {
	vendor: Vendor.Decorated;
}

export const LandscapeGalleryView: FC<LandscapeGalleryViewProps> = (props) => {
	const { vendor } = props;
	const carouselMedia = vendor.mediaSummary.media;

	const [isUnifiedLightboxModalOpen, setIsUnifiedLightboxModalOpen] =
		useState<boolean>(false);
	const setOpenUnifiedLightbox = useSetAtom(openUnifiedLightboxAtom);

	const handleLandscapeSeeAllClick = useCallback(async () => {
		afterNextPaint(() => {
			setOpenUnifiedLightbox({
				indexInAllMedia: 0,
			});
			setIsUnifiedLightboxModalOpen(true);
		});
	}, []);

	const handleLandscapeGalleryClick = useCallback(async (index: number) => {
		afterNextPaint(() => {
			setOpenUnifiedLightbox({
				indexInAllMedia: index,
				filter: getFilterByMediaType(carouselMedia?.[index].mediaType),
			});
			setIsUnifiedLightboxModalOpen(true);
		});
	}, []);

	return (
		<>
			<Gallery
				lazy
				onMediaClick={handleLandscapeGalleryClick}
				onSeeAllClick={handleLandscapeSeeAllClick}
				// onVirtualTourClick={handleSeeAllVirtualToursClick}
			/>
			{isUnifiedLightboxModalOpen && (
				<UnifiedLightboxModal
					entryPoint="Reviews"
					isModalOpen={isUnifiedLightboxModalOpen}
					closeCallback={() => setIsUnifiedLightboxModalOpen(false)}
				/>
			)}
		</>
	);
};

const mapStateToProps = (state: Redux.State): LandscapeGalleryViewProps => ({
	vendor: state.vendor.vendor as Vendor.Decorated,
});

export default connect<LandscapeGalleryViewProps, {}, {}>(mapStateToProps)(
	LandscapeGalleryView,
);
