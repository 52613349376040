import Calendar from '@components/calendar/Calendar';
import type { AvailabilityResponse } from '@components/calendar/types';
import { useStorefrontEmptyStates } from '@feature-flags/hooks/Storefront/useStorefrontEmptyStates';
import { Bone, Skeleton } from '@xo-union/tk-ui-skeleton';
import { Caption, H4 } from '@xo-union/tk-ui-typography';
import { Body1 } from '@xo-union/tk-ui-typography';
import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import React from 'react';
import RfqModal from '../../containers/InlineRFQ/components/RfqModal/RfqModal';
import { useInlineRfqForm } from '../../containers/InlineRFQ/hooks/useInlineRfqForm/useInlineRfqForm';
import RfqButton from '../../containers/cta_box/RfqButton';
import type { NavItemProps } from '../../containers/nav/types';
import { EmptyStateSearchLink } from '../EmptyStateSearchLink/EmptyStateSearchLink';
import Styles from './AvailabilitySection.scss';
import { availabilityLoading } from './availability-atoms';

export type AvailabilitySectionProps = {
	availabilityData: AvailabilityResponse | null;
};

export interface AvailabilitySectionEmptyStateProps extends NavItemProps {
	title: string;
}

export const AvailabilitySectionEmptyState = () => (
	<>
		<H4 className={Styles.sectionTitle} data-testid="availability-empty">
			Availability
		</H4>
		<EmptyStateSearchLink
			header="No availability details yet"
			text="Looking for the perfect match?"
			linkText="Continue browsing vendors"
			storefrontSection="Availability"
		/>
	</>
);

const AvailabilitySection = ({
	availabilityData,
	className,
}: AvailabilitySectionProps & NavItemProps) => {
	const formContext = useInlineRfqForm({
		initiator: 'Availability Calendar CTA',
	});
	const isEmptyStates = useStorefrontEmptyStates();
	const isLoading = useAtomValue(availabilityLoading);
	const shouldShowAvailabilityEmptyStates = isEmptyStates && !availabilityData;

	const renderRfqButton = () => (
		<RfqButton
			key="availability-rfq-button"
			size="sm"
			color="secondary"
			label="Ask about a specific date"
			handleCTAClick={formContext.openModal}
			styleName={Styles.availabilityRfqButton}
		/>
	);

	const renderLegend = () => (
		<div className={Styles.legendContainer}>
			<div className={Styles.legendIcon} />
			<div>
				<Caption size="lg">Full availability</Caption>
			</div>
			<div className={`${Styles.legendIcon} ${Styles.partial}`} />
			<div>
				<Caption size="lg">Limited availability</Caption>
			</div>
		</div>
	);

	if (!isEmptyStates && !availabilityData) {
		return null;
	}

	if (isLoading) {
		return (
			<>
				<H4 className={Styles.sectionTitle}>Availability</H4>
				<Skeleton>
					<Bone style={{ height: 318, width: '100%', borderRadius: 8 }} />
				</Skeleton>
			</>
		);
	}

	return shouldShowAvailabilityEmptyStates ? (
		<AvailabilitySectionEmptyState />
	) : (
		<div className={classNames(Styles.availabilitySectionContainer, className)}>
			<RfqModal context={formContext} />
			<H4 className={Styles.sectionTitle} data-testid="availability">
				Availability
			</H4>
			<Calendar availabilityData={availabilityData} />
			{renderLegend()}
			<Body1 className={Styles.sectionCaption}>
				Reach out to this vendor to confirm their availability, as recent
				changes may not be reflected in this calendar{' '}
			</Body1>
			{renderRfqButton()}
		</div>
	);
};

export default AvailabilitySection;
