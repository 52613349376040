import { FEATURE_FLAGS } from '@feature-flags/constants';
import { useFeatureFlags } from '@feature-flags/use-feature-flags';
import { useAppSelector } from '@redux/hooks';

const ALLOWED_MARKET_CODES = ['020', '065', '017'];

export const useStorefrontBookingTest2 = (): boolean => {
	const vendor = useAppSelector((state) => state.vendor.vendor);
	const featureFlags = useFeatureFlags();

	if (!vendor) return false;

	const isMarketCodeValid =
		vendor.categoryCode === 'WPH'
			? true
			: ALLOWED_MARKET_CODES.includes(vendor.marketCode);

	return Boolean(
		featureFlags[FEATURE_FLAGS.STOREFRONT_BOOKING_TEST_2] &&
			vendor.isPaid &&
			isMarketCodeValid,
	);
};
