import { useAppSelector } from '@redux/hooks';
import { DisplayLink } from '@xo-union/tk-ui-links';
import { Body1 } from '@xo-union/tk-ui-typography';
import React, { useCallback } from 'react';

import {
	type StorefrontSection,
	useTrackStorefrontInteraction,
} from '@hooks/useTrackStorefrontInteraction';
import classnames from 'classnames';
import Styles from './EmptyStateSearchLink.scss';

export interface EmptyStateSearchLinkProps {
	header: string;
	text: string;
	linkText: 'Continue browsing vendors';
	storefrontSection: StorefrontSection;
	inline?: boolean;
}

export const EmptyStateSearchLink = ({
	header,
	text,
	linkText,
	storefrontSection,
	inline = true,
}: EmptyStateSearchLinkProps) => {
	const cityUrl = useAppSelector(
		(state) => state.vendor.vendor?.breadcrumbInfo?.cityUrl,
	);
	const track = useTrackStorefrontInteraction(storefrontSection);

	const onClickHandler = useCallback(() => {
		track(`${linkText} clicked`);
	}, [track, linkText]);

	if (!cityUrl) {
		return null;
	}

	return (
		<div className={Styles.emptyStateSearchLinkWrapper}>
			<div className={Styles.emptyStateSearchLink}>
				<Body1 bold className={Styles.emptyStateSearchHeader}>
					{header}
				</Body1>
				<div
					className={classnames(Styles.emptyStateSearchContent, {
						[Styles.inline]: inline,
					})}
				>
					{text}
					<DisplayLink
						href={cityUrl}
						title={linkText}
						onClick={onClickHandler}
						className={Styles.cityLink}
					>
						{linkText}
					</DisplayLink>
				</div>
			</div>
		</div>
	);
};
