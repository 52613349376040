import UnifiedLightboxModal from '@components/unified-lightbox/Lightbox';
import {
	type VendorMediaKey,
	openUnifiedLightboxAtom,
} from '@components/unified-lightbox/atoms';
import { useStorefrontEmptyStates } from '@feature-flags/hooks/Storefront/useStorefrontEmptyStates';
import { useAppSelector } from '@redux/hooks';
import { useSetAtom } from 'jotai';
import React, { useState } from 'react';
import type { FCWithChildren } from 'types/react-extended';
import { afterNextPaint } from '../../../../../helpers/after-next-paint';
import Gallery from '../Gallery/Gallery';
import NavSection from '../nav/components/nav_section';
import Styles from './StorefrontGallery.scss';
import NewMobileGallery from './mobile_gallery/MobileGallery';

const PhotosSection: FCWithChildren = ({ children }) => (
	<NavSection navId="navPhotos" className={Styles.photoCarouselContainer}>
		{children}
	</NavSection>
);

export const StorefrontGallery = () => {
	const isMobile = useAppSelector((state) => state.viewport.isMobile);
	const [isUnifiedLightboxModalOpen, setIsUnifiedLightboxModalOpen] =
		useState<boolean>(false);

	const isEmptyStateEnabled = useStorefrontEmptyStates();

	const setOpenUnifiedLightbox = useSetAtom(openUnifiedLightboxAtom);

	if (isMobile) {
		return <NewMobileGallery />;
	}

	const handleOpenUnifiedLightbox = async (
		indexInAllMedia: number,
		filter: VendorMediaKey,
	) => {
		afterNextPaint(() => {
			setOpenUnifiedLightbox({ indexInAllMedia, filter, isEmptyStateEnabled });
			setIsUnifiedLightboxModalOpen(true);
		});
	};

	return (
		<>
			<PhotosSection>
				<Gallery openUnifiedLightbox={handleOpenUnifiedLightbox} />
			</PhotosSection>
			{isUnifiedLightboxModalOpen && (
				<UnifiedLightboxModal
					entryPoint="Mosaic"
					isModalOpen={isUnifiedLightboxModalOpen}
					closeCallback={() => setIsUnifiedLightboxModalOpen(false)}
				/>
			)}
		</>
	);
};

export default StorefrontGallery;
