import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { useAppSelector } from '@redux/hooks';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Decorated } from 'types/vendor';
import VendorAnalytics from '../../decorators/vendorAnalytics';

type Event = 'Milestone Viewed';
type MileStone =
	| 'Availability'
	| 'Packages'
	| 'About vendor'
	| 'What we offer'
	| 'Content highlights'
	| 'Real Weddings'
	| 'Venue Pricing Highlights'
	| 'Venue Pricing Section & FAQs'
	| 'Review AI Summary'
	| 'Verified Reviews Box'
	| 'Detailed Pricing Packages'
	| 'Google Reviews';

const options: IntersectionObserverInit = {
	root: null,
	rootMargin: '0px',
	threshold: 1,
};

export const useTrackStorefrontImpression = (
	milestone: MileStone,
	event: Event = 'Milestone Viewed',
	properties?: object,
	getExtraProperties?: () => object,
) => {
	const ref = useRef(null);
	const [, setHasTracked] = useState(false);
	const { track } = useAnalyticsContext();
	const vendor = useAppSelector((state) => state.vendor.vendor as Decorated);
	const vendorAnalytics = useMemo(() => new VendorAnalytics(vendor), [vendor]);

	const trackCallback = useCallback(
		(entries: IntersectionObserverEntry[]) => {
			const [entry] = entries;
			setHasTracked((prevState) => {
				if (prevState) return prevState;
				const tracked = Boolean(
					!prevState && entry.intersectionRatio === 1 && vendorAnalytics,
				);

				if (tracked) {
					track({
						event,
						properties: {
							...vendorAnalytics.baseEventProps(),
							...(properties ?? {}),
							...(getExtraProperties ? getExtraProperties() : {}),
							milestone,
						},
					});
				}

				return tracked;
			});
		},
		[milestone, event, track, properties, vendorAnalytics, getExtraProperties],
	);

	useEffect(() => {
		const observer = new IntersectionObserver(trackCallback, options);

		if (ref.current) {
			observer.observe(ref.current);
		}

		return () => {
			if (ref.current) {
				observer.unobserve(ref.current);
			}
		};
	}, [ref, trackCallback]);

	return ref;
};
