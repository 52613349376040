import Icon from '@xo-union/tk-component-icons';
import { Body1, Hero } from '@xo-union/tk-ui-typography';
import { useAtomValue } from 'jotai';
import React, { type CSSProperties } from 'react';
import { scrollbarWidthAtom } from '../../../../jotai/shared/scrollbar-width';
import Styles from './styles.scss';

const WhyUseKnotFullWidth = () => {
	const scrollbarWidth = useAtomValue(scrollbarWidthAtom);
	return (
		<div className={Styles.parentContainer}>
			<div
				className={Styles.backgroundFullWidth}
				style={
					{
						'--scrollbar-width': `${scrollbarWidth}px`,
					} as CSSProperties
				}
			/>
			<Body1 className={Styles.content}>
				<Hero as="h2">Why use The Knot to message vendors?</Hero>
				<ul>
					<li>
						<Icon name="check" size="sm" />
						<span>
							Messaging our verified vendors on The Knot is free, safe and
							secure.
						</span>
					</li>
					<li>
						<Icon name="check" size="sm" />
						<span>
							Conveniently track vendor messages and planning details all in one
							place.
						</span>
					</li>
					<li>
						<Icon name="check" size="sm" />
						<span>
							Our mobile apps make it easy to stay in touch with vendors while
							you're on‑the‑go.
						</span>
					</li>
					<li>
						<Icon name="check" size="sm" />
						<span>
							For personalized pricing and package details, sending the vendor a
							message is the fastest way to get info.
						</span>
					</li>
				</ul>
			</Body1>
		</div>
	);
};
export default WhyUseKnotFullWidth;
