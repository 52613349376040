import { useStorefrontBookingTest1 } from '@feature-flags/hooks/Storefront/useStorefrontBookingTest1';
import { inboxUrl } from '@settings';
import { Button } from '@xo-union/tk-component-buttons';
import React, { type FC, useMemo } from 'react';
import { connect } from 'react-redux';
import type { UseInlineRfqFormReturn } from '../../../../hooks/useInlineRfqForm/useInlineRfqForm';
import { checkIsTablet, isPostPrimary } from '../../../../utils';
import { buildUrl } from '../../../ViewConversation/utils';
import ViewConversationLink from '../../../ViewConversationLink';
import Styles from '../../styles.scss';

export interface StateProps {
	code: Category.CategoryCode;
	conversations: MessagedVendors.Conversations;
	vendorId: Vendor.Raw['id'];
	viewport: Redux.Viewport;
}

export interface OwnProps {
	context: UseInlineRfqFormReturn;
}

type StickyCtaBoxCtaProps = StateProps & OwnProps;

export const StickyCtaBoxCta: FC<StickyCtaBoxCtaProps> = (props) => {
	const { code, conversations, vendorId, viewport, context } = props;
	const isTablet = checkIsTablet(viewport);
	const { shouldShowErrors, areErrorsInForm, isModalOpen } = context;
	const url = buildUrl({ conversations, inboxUrl, vendorId });
	const isBookingTest1 = useStorefrontBookingTest1();

	const text = useMemo(() => {
		if (code === 'BEA') {
			return 'Schedule Appointment';
		}

		return 'Request Quote';
	}, [code]);

	if (
		isPostPrimary(
			conversations,
			vendorId,
			isModalOpen,
			shouldShowErrors,
			areErrorsInForm,
		)
	) {
		return (
			<ViewConversationLink
				isBlock={viewport.isMobile || isTablet}
				href={url}
			/>
		);
	}

	return (
		<Button
			className={Styles.stickyCtaButton}
			block
			color={isBookingTest1 ? 'secondary' : 'primary'}
			onClick={context.openModal}
			size="lg"
		>
			{text}
		</Button>
	);
};

const mapStateToProps = (state: Redux.State) => ({
	code: state.category.code,
	conversations: state.messagedVendors.conversations,
	vendorId: state.vendor.vendorRaw?.id,
	viewport: state.viewport,
});

export default connect(mapStateToProps)(StickyCtaBoxCta);
