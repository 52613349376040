import throttle from '@utils/throttle';
import { atom } from 'jotai';

const store = atom(0);

store.onMount = (set) => {
	const callback = throttle(() => {
		set(window.innerWidth - document.body.clientWidth);
	}, 250);
	window.addEventListener('resize', callback);
	callback();
	return () => window.removeEventListener('resize', callback);
};

export const scrollbarWidthAtom = atom((get) => get(store));
