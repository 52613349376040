import { FEATURE_FLAGS } from '@feature-flags/constants';
import { useFeatureFlags } from '@feature-flags/use-feature-flags';
import { useAppSelector } from '@redux/hooks';
import { useAtomValue } from 'jotai/index';
import { useMemo } from 'react';
import { detailedPricingUsuallySpendAtom } from '../../../jotai/storefront/detailed-pricing';
import { couplesUsuallySpendAtom } from '../../../jotai/storefront/pricing';

export const useStorefrontCouplesUsuallySpend = (): boolean => {
	const featureFlags = useFeatureFlags();
	const vendor = useAppSelector((state) => state.vendor.vendor);
	const couplesUsuallySpendDetailedPricing = useAtomValue(
		detailedPricingUsuallySpendAtom,
	);
	const couplesUsuallySpend = useAtomValue(couplesUsuallySpendAtom);

	const hasCouplesUsuallySpend = useMemo(() => {
		const categoryCode = vendor?.categoryCode || '';
		if (categoryCode === 'REC') {
			return Boolean(couplesUsuallySpend);
		}

		if (['WPH', 'FLO', 'VID'].includes(categoryCode)) {
			return Boolean(couplesUsuallySpendDetailedPricing);
		}

		return false;
	}, [
		couplesUsuallySpendDetailedPricing,
		couplesUsuallySpend,
		vendor?.categoryCode,
	]);

	return Boolean(
		featureFlags[FEATURE_FLAGS.STOREFRONT_COUPLES_USUALLY_SPEND] &&
			vendor?.isPaid &&
			hasCouplesUsuallySpend,
	);
};
